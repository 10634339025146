import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useQuery } from 'apollo-client'
import dayjs from 'date'

import { constants } from 'helpers'
import { useFt } from 'hooks'

import { useLocalePreferences } from 'modules/localePreferences'
import { useUser } from 'modules/user'

import websiteTakeoverQuery from './graph/websiteTakeover.strapi.graphql'


const useStrapiWSTOData = () => {
  const isStrapiWebsiteTakeoverEnabled = useFt(constants.features.strapiWebsiteTakeover)
  const isStrapiLocalizationEnabled = useFeatureIsOn(constants.features.strapiLocalization)
  const isStrapiLocalizationWstoEnabled = useFeatureIsOn(constants.features.strapiLocalizationWsto)

  const { dateTime } = useUser()
  const { localePreferences } = useLocalePreferences()

  const nowDateTime = dayjs(dateTime).startOf('hour').utc().format()

  const filters = {
    and: [
      isStrapiLocalizationEnabled && {
        or: [
          { locale: { null: true } },
          // retrieve wsto with fallback locale when feature is disabled
          { locale: { containsi: isStrapiLocalizationWstoEnabled ? localePreferences?.locale : 'en-US' } },
        ],
      },
      {
        or: [
          { startsFrom: { null: true } },
          { startsFrom: { lte: nowDateTime } },
        ],
      },
      {
        or: [
          { endsAfter: { null: true } },
          { endsAfter: { gte: nowDateTime } },
        ],
      },
    ].filter(Boolean),
  }

  const { data, isFetching } = useQuery(websiteTakeoverQuery, {
    client: 'strapi',
    skip: !isStrapiWebsiteTakeoverEnabled,
    variables: {
      filters,
      sort: isStrapiLocalizationEnabled ? [ 'locale:desc' ] : undefined,
    },
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    context: {
      ssrCache: true,
    },
  })

  const websiteTakeoverData = data?.websiteTakeovers?.data?.[0]?.attributes

  return {
    isFetching,
    data: websiteTakeoverData,
  }
}

export default useStrapiWSTOData
